<template>
  <div>
    <div class="rm4-main" v-if="isDomainAvailable">
      <div class="rm4-main__container">
        <h1 class="rm4-main__terras" v-if="$store.isOutside">Terras</h1>
        <div
          v-if="
            $store.currentStep !== $step.FIFTH &&
              $store.currentStep !== $step.LAST &&
              $store.isPreferred &&
              !showMessageOverlay &&
              $store.message
          "
          class="rm4-notification rm4-notification--info"
        >
          {{ $store.message }}
        </div>
        <time-person
          v-if="$store.currentStep === $step.FIRST && !isPreferredConfigLoading"
        />
        <day-time-select v-if="$store.currentStep === $step.SECOND" />
        <time-select v-if="$store.currentStep === $step.THIRD" />
        <vip-confirm v-if="$store.currentStep === $step.VIP" />
        <customer-info v-if="$store.currentStep === $step.FOURTH" />
        <confirm v-if="$store.currentStep === $step.FIFTH" />
        <complete v-if="$store.currentStep === $step.LAST" />
      </div>
    </div>

    <div class="rm4-finger-print">
      <template v-if="$store.fpImageData">
        <img :src="$store.fpImageData" />
      </template>
      <template v-if="$store.fpIframe">
        <iframe :src="$store.fpIframe"></iframe>
      </template>
    </div>

    <modal v-if="$store.message" :show="showMessageOverlay">
      <template v-slot:content>
        <div class="rm4-modal__slot-content">
          <div class="rm4-modal__slot-content__message">
            {{ $store.message }}
          </div>
          <div
            class="rm4-button rm4-button--primary rm4-modal__slot-content--close"
            @click="showMessageOverlay = false"
          >
            Close
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import Complete from "./components/Complete";
import Confirm from "./components/Confirm";
import CustomerInfo from "./components/CustomerInfo";
import DayTimeSelect from "./components/DayTimeSelect";
import Modal from "./components/Modal";
import TimePerson from "./components/TimePerson";
import TimeSelect from "./components/TimeSelect";
import VipConfirm from "./components/VipConfirm";

export default {
  name: "app",
  props: ["domain"],
  data: function() {
    return {
      isDomainAvailable: false,
      isPreferredConfigLoading: true,
      showMessageOverlay: false
    };
  },
  components: {
    TimePerson,
    DayTimeSelect,
    TimeSelect,
    VipConfirm,
    CustomerInfo,
    Confirm,
    Complete,
    Modal
  },
  async mounted() {
    this.isDomainAvailable = await this.setDomain(this.domain);

    try {
      let url = `${this.$apiHost}?nocache=${new Date().getTime()}`;
      const { data } = await axios.post(url, {
        action: "gethoreca",
        domain: this.$domain
      });
      this.$store.horecaLocation = _.get(data, "horeca");
      this.getPreferredConfig(_.get(data, "horeca"));
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    async getPreferredConfig(locationId) {
      try {
        const action = "action=customer_preferred_config";
        const location = `&locationID=${locationId}`;
        const noCache = `&nocache=${new Date().getTime()}`;
        const url = `${this.$apiHost}?${action}${location}${noCache}`;
        const { data } = await axios.get(url);
        this.$store.isPreferred = Number(_.get(data, "enabled", false));
      } catch (error) {
        console.error(error);
      }

      this.isPreferredConfigLoading = false;
    }
  },
  watch: {
    "$store.currentStep": {
      handler(newStep, prevStep) {
        const steps = Object.values(this.$step);
        if (steps.indexOf(prevStep) < steps.indexOf(newStep)) {
          this.showMessageOverlay =
            this.$store.isPreferred &&
            this.$store.currentStep === this.$step.SECOND;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.rm4-main {
  position: relative;
  max-width: 400px;
  width: 100%;
  margin: 16px auto;
  padding: 0 16px;

  .rm4-main__container {
    position: relative;

    .rm4-main__terras {
      text-align: center;
      font-size: 1.3em;
    }
  }
}

.rm4-finger-print {
  visibility: hidden;
  position: absolute;
  left: -99999px;
}

.rm4-modal__slot-content {
  display: flex;
  flex-direction: column;
  width: 100%;

  .rm4-modal__slot-content__message {
    margin-top: 2px;
    margin-bottom: 32px;
  }

  .rm4-modal__slot-content--close {
    width: 60px;
    margin: 0;
    align-self: flex-end;
    font-size: 16px;
  }
}
</style>

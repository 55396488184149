<template>
  <div>
    <div class="rm4-card rm4-card--auto-height rm4-card--p1-2">
      <InputOverview :picker="true"></InputOverview>
    </div>

    <div class="rm4-card rm4-card--p2-1">
      <div class="rm4-time-grid">
        <div class="rm4-time-grid__container" v-for="time in times" :key="time">
          <div
            class="rm4-time-grid__time-slot"
            :class="[
              {
                'rm4-time-grid__time-slot--selected':
                  time[0].timetxt === $store.time
              },
              getStatusClass(time[0].status)
            ]"
            @click="process(time[0])"
          >
            <div class="rm4-time-grid__time-slot__time">
              <span
                v-if="time[0].status === status.OUTSIDE"
                class="material-symbols-outlined"
                >deck</span
              >
              <span>{{ time[0].timetxt }}</span>
            </div>
            <div
              v-if="time[0].endtime && !isStatusBlocked(time[0])"
              class="rm4-time-grid__time-slot__endtime"
            >
              till {{ time[0].endtime }}
            </div>
          </div>
        </div>
      </div>
      <div v-show="$store.loading" class="rm4-loader"></div>
    </div>

    <div class="rm4-actions">
      <div class="rm4-button" @click="goBack">Back</div>
    </div>

    <modal
      name="rm4-blocked-modal"
      :height="'auto'"
      :adaptive="true"
      :classes="'rm4-modal-content'"
    >
      <div class="rm4-modal-container">
        <div class="rm4-modal-close" @click="$modal.hide('rm4-blocked-modal')">
          <span class="rm4-modal-close-icon">&#10005;</span>
        </div>
        <div v-html="blockedText"></div>
      </div>
    </modal>

    <modal
      name="rm4-terras-modal"
      :height="'auto'"
      :adaptive="true"
      :classes="'rm4-modal-content'"
    >
      <div class="rm4-modal-container rm4-modal-container--terras">
        <div class="rm4-modal-close" @click="$modal.hide('rm4-terras-modal')">
          <span class="rm4-modal-close-icon">&#10005;</span>
        </div>
        <div class="rm4-modal-container--terras__bold-text">
          This reservation is for <strong>OUTSIDE</strong>, on our lovely
          terrace.
        </div>
        <p class="rm4-modal-container--terras__small-text"></p>
        <p v-if="endTimeValue">{{ endtimeText }}</p>
        <div class="rm4-modal-container--terras__large-text">
          Do you want a table outside?
        </div>

        <div class="rm4-actions rm4-actions--modal">
          <div class="rm4-button rm4-button--primary" @click="setTerrasTime">
            Yes
          </div>
          <div class="rm4-button" @click="$modal.hide('rm4-terras-modal')">
            Back
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="rm4-endtime-modal"
      :height="'auto'"
      :adaptive="true"
      :classes="'rm4-modal-content'"
    >
      <div class="rm4-modal-container">
        <div class="rm4-modal-close" @click="$modal.hide('rm4-endtime-modal')">
          <span class="rm4-modal-close-icon">&#10005;</span>
        </div>
        <div>{{ endtimeText }} Are you sure you want to continue?</div>
        <div class="rm4-actions rm4-actions--modal">
          <div
            class="rm4-button rm4-button--tertiary"
            @click="process(endTimeValue, true)"
          >
            Ok
          </div>
          <div class="rm4-button" @click="$modal.hide('rm4-endtime-modal')">
            Cancel
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import InputOverview from "./InputOverview";

const dateToday = moment().format("YYYY-MM-DD");
const STATUS = {
  OPEN: "open",
  VIP: "vip",
  OUTSIDE: "terras",
  BLOCKED: "blocked"
};

export default {
  name: "TimeSelect",
  components: { InputOverview },
  data: function() {
    return {
      times: [],
      selectedEndtime: null,
      endTimeValue: null,
      terrasTime: null,
      status: STATUS
    };
  },
  created() {
    this.processTimes();
  },
  methods: {
    processTimes() {
      const filtered = _.filter(this.$store.timeSlots, time =>
        moment(`${dateToday} ${_.get(time, "0.timetxt")}`).isBetween(
          moment(`${dateToday} ${this.$store.frame.time_from}`),
          moment(`${dateToday} ${this.$store.frame.time_to}`),
          undefined,
          "[]"
        )
      );

      if (!_.size(filtered)) {
        this.$store.currentStep = this.$step.SECOND;
      }

      this.times = _.map(filtered, time => {
        _.set(time, "0.status", this.timeClass(time));
        return time;
      });

      const currentTime = _.find(this.times, time => {
        return time[0].timetxt === this.$store.time;
      });

      if (currentTime && currentTime[0].status === STATUS.BLOCKED) {
        this.$store.time = null;
      }
    },
    timeClass(time) {
      if (
        Number(_.get(time, "0.maxpax")) < 1 &&
        _.get(time, "0.specialmaxpax") &&
        Number(_.get(time, "0.specialmaxpax")) > 0 &&
        Number(_.get(time, "0.specialmaxpax")) >= this.peopleCount
      ) {
        return STATUS.VIP;
      }

      if (
        Number(_.get(time, "0.maxpax")) < 1 ||
        this.peopleCount > _.get(time, "0.maxpax")
      ) {
        if (_.get(time, "0.maxpaxoutside") > this.peopleCount) {
          return STATUS.OUTSIDE;
        }

        return STATUS.BLOCKED;
      }

      return STATUS.OPEN;
    },
    process(time, skipModal = false) {
      this.selectedEndtime = _.get(time, "endtime");

      if (this.selectedEndtime) {
        if (!skipModal && !this.isStatusBlocked(time)) {
          this.endTimeValue = time;

          if (_.get(time, "status") !== STATUS.OUTSIDE) {
            this.$modal.show("rm4-endtime-modal");
            return;
          }
        }
      } else {
        this.endTimeValue = null;
      }

      switch (_.get(time, "status")) {
        case STATUS.OPEN:
          this.$store.isOutside = false;
          this.$store.isVip = false;
          this.$store.time = _.get(time, "timetxt");
          this.$store.endTime = _.get(time, "endtime");
          this.$store.isPreferredTimeslot = _.get(time, "pf", 0);
          this.$store.currentStep = this.$step.FOURTH;
          break;
        case STATUS.VIP:
          this.$store.isOutside = false;
          this.$store.time = _.get(time, "timetxt");
          this.$store.endTime = _.get(time, "endtime");
          this.$store.currentStep = this.$step.VIP;
          this.$store.isPreferredTimeslot = _.get(time, "pf", 0);
          break;
        case STATUS.OUTSIDE:
          this.terrasTime = time;
          this.$modal.show("rm4-terras-modal");
          break;
        case STATUS.BLOCKED:
          this.$modal.show("rm4-blocked-modal");
          break;
      }
    },
    setTerrasTime() {
      this.$store.isOutside = true;
      this.$store.isVip = false;
      this.$store.time = _.get(this.terrasTime, "timetxt");
      this.$store.endTime = _.get(this.terrasTime, "endtime");
      this.$store.isPreferredTimeslot = _.get(this.terrasTime, "pf", 0);
      this.$store.currentStep = this.$step.FOURTH;
    },
    goBack() {
      this.$store.autoProceed = false;
      this.$store.currentStep = this.$step.SECOND;
    }
  },
  computed: {
    peopleCount: function() {
      return this.$store.adults + this.$store.kids;
    },
    blockedText: function() {
      return (
        _.get(this.$store, "groups.msg") ||
        _.get(this.$store, "info.text_full") ||
        `Max. reservations reached for this timeframe.
        NO PROBLEM, Please drop by without reservation.
        Maybe you have to wait a few minutes for a free
        table, but you can have a drink in our lounge.
        (we always keep enough tables free for walk-in)`
      );
    },
    endtimeText: function() {
      return `You have selected a table time with an end time of ${this.selectedEndtime}.`;
    },
    isStatusBlocked: function() {
      return time => time.status.includes("rm4-blocked");
    },
    getStatusClass: function() {
      return status => `rm4-time-grid__time-slot--${status}`;
    }
  },
  watch: {
    "$store.timeSlots": {
      handler: function() {
        this.processTimes();
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
.rm4-card--auto-height {
  z-index: 1;
}

.rm4-time-grid {
  display: flex;
  flex-wrap: wrap;

  .rm4-time-grid__container {
    width: 25%;

    .rm4-time-grid__time-slot {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 39px;
      font-size: 15px;
      text-align: center;
      padding: 0 8px;
      margin: 8px;
      border-radius: 11px;

      .rm4-time-grid__time-slot__time {
        display: flex;
        justify-content: center;
        align-items: center;

        .material-symbols-outlined {
          font-size: 16px;
          margin-right: 4px;
        }
      }

      &.rm4-time-grid__time-slot--selected {
        color: #ffffff !important;
        background: #00bfff !important;
      }

      &.rm4-time-grid__time-slot--open {
        color: #c1ffc1;
        background: #50bd50;
      }

      &.rm4-time-grid__time-slot--vip {
        color: #90f492;
        background: #036705;
      }

      &.rm4-time-grid__time-slot--terras {
        color: #c1ffc1;
        background: #389438;
      }

      &.rm4-time-grid__time-slot--blocked {
        color: #d6cfb9;
        background: #5d5336;
      }

      &:hover {
        cursor: pointer;
      }

      .rm4-time-grid__time-slot__endtime {
        font-size: 10px;
      }
    }
  }
}

.rm4-endtime-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;

  .rm4-endtime-actions__button {
    width: 49%;
    height: 40px;
    font-size: 19px;
    border-radius: 11px;
    border: none;
    cursor: pointer;

    &.rm4-endtime-actions__button--primary {
      background: #00bfff;
      color: #ffffff;
    }
  }
}

.rm4-modal-container--terras {
  text-align: center;

  .rm4-modal-container--terras__bold-text {
    font-weight: bold;
    margin-bottom: 16px;
  }

  .rm4-modal-container--terras__small-text {
    font-size: 67%;
    margin-top: 0px;
  }

  .rm4-modal-container--terras__large-text {
    margin-bottom: 16px;
  }
}

@media (max-width: 576px) {
  .rm4-time-grid {
    .rm4-time-grid__container {
      width: 33.33%;
    }
  }
}
</style>

<template>
  <div>
    <div class="rm4-card rm4-card--auto-height rm4-card--p1-2">
      <InputOverview :picker="true"></InputOverview>
    </div>

    <div class="rm4-card rm4-card--p2-2">
      <div class="rm4-day-time">
        <div class="rm4-card__label">
          {{
            frames.length
              ? "Which part of the day?"
              : "For today no more time slots are available online. Check another day."
          }}
        </div>
        <template v-for="frame of frames">
          <div class="rm4-day-time__button" @click="setTime(frame)" :key="frame.ID">
            {{ frame.new_label }}
          </div>
        </template>
      </div>
      <div v-show="$store.loading" class="rm4-loader"></div>
    </div>

    <div class="rm4-actions">
      <div class="rm4-button" @click="$store.currentStep = $step.FIRST">
        Back
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import InputOverview from "./InputOverview";

const dateToday = moment().format("YYYY-MM-DD");

export default {
  name: "DayTimeSelect",
  components: { InputOverview },
  data: function() {
    return {
      frames: []
    };
  },
  created() {
    this.setFrames();
  },
  methods: {
    setFrames() {
      this.frames = _.filter(
        _.map(this.$store.frameConfig, frame => {
          const times = _.map(
            _.filter(this.$store.timeSlots, time => {
              return moment(
                `${dateToday} ${_.get(time, "0.timetxt")}`
              ).isBetween(
                moment(`${dateToday} ${frame.time_from}`),
                moment(`${dateToday} ${frame.time_to}`),
                undefined,
                "[]"
              );
            }),
            item => _.first(item)
          );

          if (!_.size(times)) {
            return null;
          }

          const data = { ...frame };
          const startTime = _.get(_.first(times), "timetxt");
          const endTime = _.get(_.last(times), "timetxt");
          const oldLabel = frame.label ? `${frame.label} ` : "";
          const timeLabel =
            _.size(times) > 1 ? `${startTime} - ${endTime}` : startTime;
          _.set(data, "new_label", `${oldLabel}${timeLabel}`);

          return data;
        })
      );

      if (_.size(this.frames) === 1 && this.$store.autoProceed) {
        this.setTime(_.first(this.frames));
      }
    },
    setTime(frame) {
      this.$store.frame = frame;
      this.$store.currentStep = this.$step.THIRD;
    }
  },
  watch: {
    "$store.frameConfig": {
      handler: function() {
        this.setFrames();
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
.rm4-card--auto-height {
  z-index: 1;
}

.rm4-day-time {
  .rm4-day-time__button {
    text-align: center;
    color: #c1ffc1;
    padding: 11px 8px;
    margin-bottom: 1rem;
    background: #50bd50;
    border: none;
    border-radius: 11px;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>

<template>
  <div>
    <div class="rm4-card rm4-card--auto-height">
      <InputOverview :show-time="true" :inactive="true"></InputOverview>
    </div>

    <div class="rm4-card rm4-card--success rm4-card--p2-1">
      <div class="rm4-card__label">
        Success! Confirmation has been sent to your email:
        <b>{{ $store.email }}</b>
      </div>
      <div class="rm4-card__sub-label">
        Please check your spam folder if you haven't received an email.
      </div>
    </div>

    <div
      class="rm4-complete-description"
      v-if="$store.completeDesc"
      v-html="$store.completeDesc"
    ></div>

    <div class="rm4-actions">
      <div class="rm4-button" @click="reset">Go to homepage</div>
    </div>
  </div>
</template>

<script>
import InputOverview from "./InputOverview";

export default {
  name: "Complete",
  components: { InputOverview },
  methods: {
    reset() {
      this.$store.date = new Date();
      this.$store.time = null;
      this.$store.frame = null;
      this.$store.frameConfig = [];
      this.$store.adults = 2;
      this.$store.kids = 0;
      this.$store.timeSlots = [];
      this.$store.email = null;
      this.$store.firstName = null;
      this.$store.lastName = null;
      this.$store.phone = null;
      this.$store.zipCode = null;
      this.$store.keepInformed = false;
      this.$store.loading = false;
      window.location.href = "/";
    }
  }
};
</script>

<style lang="scss" scoped>
.rm4-complete-description {
  margin-bottom: 32px;
}
</style>

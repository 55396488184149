<template>
  <div class="rm4-modal" :style="{ display: !show ? 'none' : 'block' }">
    <div class="rm4-modal-content">
      <div class="rm4-modal-header">
        <span class="rm4-close" @click="show = false">&times;</span>
      </div>
      <div class="rm4-modal-body">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    show: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.rm4-modal {
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);

  .rm4-modal-content {
    background-color: #fefefe;
    margin: auto;
    border: 1px solid #888;
    max-width: 80%;
    width: 400px;
    border-radius: 5px;

    .rm4-modal-header {
      padding: 0 10px;
      margin-bottom: 10px;

      .rm4-close {
        color: #aaaaaa;
        float: right;
        font-size: 28px;
        font-weight: bold;

        &:hover,
        &:focus {
          color: #000;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }

    .rm4-modal-body {
      padding: 10px;
      font-size: 16px;
    }
  }
}
</style>

<template>
  <div>
    <div class="rm4-card rm4-card--p2-2">
      <div
        v-if="$store.error"
        v-html="$store.error"
        class="rm4-notification rm4-notification--error"
      ></div>

      <div class="rm4-form">
        <template v-if="$store.isPreferred">
          <div class="rm4-form__input-field">
            <div
              class="rm4-form__input-group"
              :class="{
                'rm4-form__input-group--invalid': $store.formError.firstName
              }"
            >
              <div class="rm4-form__input-label">First name</div>
              <input
                class="rm4-form__input"
                type="text"
                v-model="$store.firstName"
              />
            </div>
            <div
              class="rm4-form__input-group"
              :class="{
                'rm4-form__input-group--invalid': $store.formError.lastName
              }"
            >
              <div class="rm4-form__input-label">Last name</div>
              <input
                class="rm4-form__input"
                type="text"
                v-model="$store.lastName"
              />
            </div>
          </div>

          <div class="rm4-form__input-field">
            <div
              class="rm4-form__input-group"
              :class="{
                'rm4-form__input-group--invalid': $store.formError.email
              }"
            >
              <div class="rm4-form__input-label">Email</div>
              <input
                class="rm4-form__input"
                type="email"
                v-model="$store.email"
              />
            </div>
          </div>

          <div class="rm4-form__input-field">
            <div class="rm4-form__input-group">
              <div class="rm4-form__input-label">Phone number</div>
              <vue-phone-number-input
                class="rm4-form__input"
                default-country-code="NL"
                color="#000000"
                valid-color="#555555"
                error-color="#FF0000"
                :border-radius="0"
                :translations="{
                  countrySelectorLabel: '',
                  phoneNumberLabel: ''
                }"
                :no-example="true"
                :error="$store.formError.phone"
                v-on:update="updatePhone"
                v-model="phone"
              >
              </vue-phone-number-input>
            </div>
          </div>
        </template>

        <div class="rm4-form__form-field">
          <i class="rm4-icon rm4-fal rm4-fa-calendar-day"></i>
          <div class="rm4-form__input-field">
            <div class="rm4-form__input-label">Reservation date</div>
            <date-picker
              v-model="$store.date"
              value-type="date"
              prefix-class="rm4"
              format="ddd, MMM DD YYYY"
              :lang="lang"
              :editable="false"
              :clearable="false"
              :append-to-body="false"
              :disabled-date="disabledBeforeToday"
              v-on:change="getTimesAndSpecialData"
              v-on:open="showPersonInput = false"
            >
            </date-picker>
          </div>
        </div>

        <div class="rm4-form__form-field">
          <i class="rm4-icon rm4-fal rm4-fa-user-friends"></i>
          <div class="rm4-form__input-field">
            <div class="rm4-form__input-label">Persons</div>
            <transition name="rm4-zoom-in-down">
              <div
                class="rm4-card rm4-card--auto-height rm4-person-select"
                v-if="showPersonInput"
              >
                <div class="rm4-person-select__selection">
                  <div class="rm4-person-select__label">Adults</div>
                  <div class="rm4-person-select__calculate">
                    <i
                      class="rm4-fal rm4-fa-minus-circle"
                      @click="subtract('adults')"
                    >
                    </i>
                    <span>{{ adults }}</span>
                    <i class="rm4-fal rm4-fa-plus-circle" @click="adults += 1">
                    </i>
                  </div>
                </div>
                <div class="rm4-person-select__selection">
                  <div class="rm4-person-select__label">Kids</div>
                  <div class="rm4-person-select__calculate">
                    <i
                      class="rm4-fal rm4-fa-minus-circle"
                      @click="subtract('kids')"
                    >
                    </i>
                    <span>{{ kids }}</span>
                    <i
                      class="rm4-fal rm4-fa-plus-circle"
                      @click="kids += 1"
                    ></i>
                  </div>
                </div>
                <div
                  class="rm4-button rm4-button--primary rm4-button--w-100 rm4-button--m-0"
                  @click="updatePersons"
                >
                  Apply
                </div>
              </div>
            </transition>
            <div class="rm4-person-picker">
              <input
                class="rm4-person-picker__input"
                type="text"
                :value="personLabel"
                disabled
              />
              <div
                class="rm4-person-picker__event-handler"
                @click="showPersonInput = !showPersonInput"
              ></div>
            </div>
          </div>
        </div>
      </div>

      <div v-show="$store.loading" class="rm4-loader"></div>
    </div>

    <div class="rm4-actions">
      <div
        class="rm4-button rm4-button--primary"
        :class="{
          'rm4-button--disabled':
            $store.loading ||
            $store.error ||
            ($store.isPreferred && !isValidForms())
        }"
        @click="nextStep"
      >
        Next
      </div>
    </div>

    <modal
      name="rm4-group-modal"
      :height="'auto'"
      :adaptive="true"
      :classes="'rm4-modal-content'"
    >
      <div class="rm4-modal-container">
        <div class="rm4-modal-close" @click="$modal.hide('rm4-group-modal')">
          <span class="rm4-modal-close-icon">&#10005;</span>
        </div>
        <div v-html="groupMessage"></div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import VuePhoneNumberInput from "vue-phone-number-input";
import DatePicker from "vue2-datepicker";

export default {
  name: "TimePerson",
  components: {
    DatePicker,
    VuePhoneNumberInput
  },
  data: function() {
    return {
      lang: {
        formatLocale: {
          firstDayOfWeek: 1
        }
      },
      showPersonInput: false,
      phone: this.$store.phone,
      adults: 0,
      kids: 0,
      isGroupNotified: false
    };
  },
  created() {
    this.adults = this.$store.adults;
    this.kids = this.$store.kids;
    this.$store.isOutside = false;
    this.getTimes(true);
  },
  methods: {
    updatePhone(value) {
      this.$store.phone = _.get(value, "formattedNumber");
      _.set(
        this.$store.formError,
        "phone",
        !_.get(value, "isValid") || this.$store.phone === "+31612345678"
      );
    },
    disabledBeforeToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date < today;
    },
    async updatePersons() {
      this.$store.adults = this.adults;
      this.$store.kids = this.kids;
      this.showPersonInput = false;
      const fetched = await this.getTimes();

      if (fetched) {
        this.isGroupNotified = false;
      }
    },
    subtract(type) {
      switch (type) {
        case "adults":
          if (this.adults > 1) this.adults -= 1;
          break;
        case "kids":
          if (this.kids > 0) this.kids -= 1;
          break;
      }
    },
    async getTimesAndSpecialData() {
      const fetched = await this.getTimes();

      if (fetched) {
        this.isGroupNotified = false;
      }

      this.getSpecialData();
    },
    async getSpecialData() {
      try {
        const noCache = `?nocache=${new Date().getTime()}`;
        const action = "&action=get_special_data";
        const domain = `&domain=${this.$domain}`;
        const pax = `&totalpax=${this.$store.adults + this.$store.kids}`;
        const url = `${this.$apiHost}${noCache}${action}${domain}${pax}`;
        const { data } = await axios.get(url, {
          paramsSerializer: function() {
            return "";
          }
        });
        const parsed = _.get(data, "only")
          ? data.only.replace("[price]", _.get(data, "price"))
          : null;
        _.set(data, "only", parsed);
        this.$store.specialData = data;
        this.$store.autoProceed = true;
      } catch (error) {
        console.error(error);
      }
    },
    async nextStep() {
      await this.getTimes();

      if (!this.$store.error) {
        if (!this.isGroupNotified && this.groupMessage) {
          this.$modal.show("rm4-group-modal");
          this.isGroupNotified = true;
          return;
        }

        if (this.$store.isPreferred && !this.isValidForms()) {
          return;
        }

        this.getSpecialData();
        this.$store.currentStep = this.$step.SECOND;
      }
    }
  },
  computed: {
    personLabel: function() {
      return `${this.$store.adults} adults, ${this.$store.kids} kids`;
    },
    groupMessage: function() {
      return _.get(this.$store, "groups.msg");
    }
  }
};
</script>

<style lang="scss" scoped>
.rm4-form {
  .rm4-form__input-field {
    display: flex;
    margin: 0 -8px;

    &:last-child {
      margin-bottom: 0;
    }

    .rm4-form__input-group {
      width: 100%;
      padding: 0 8px 24px;

      &.rm4-form__input-group--invalid {
        & * {
          color: red !important;
          border-color: red !important;
        }
      }

      .rm4-form__input-label {
        width: inherit;
        color: grey;
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        margin-bottom: 5px;
      }

      .rm4-form__input {
        width: inherit;
        height: 34px;
        font-size: 16px;
        color: #555;
        border-width: 0 0 1px 0;
        border-color: #ccc;
        border-radius: 0;
      }
    }
  }

  .rm4-form__form-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    .rm4-icon {
      width: 100%;
      max-width: 48px;
      min-width: 48px;
      font-size: 22px;
      margin-right: 16px;
    }

    .rm4-form__input-field {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
      margin: 0;

      ::v-deep .rm4-datepicker {
        width: 100%;

        .rm4-calendar-header {
          .rm4-btn-icon-double-left,
          .rm4-btn-icon-double-right {
            display: none;
          }
        }

        .rm4-input {
          font-size: 16px;
          padding: 0;
          border-width: 0 0 1px 0;
          border-radius: 0;
          box-shadow: none;
        }
      }

      .rm4-form__input-label {
        color: grey;
        font-size: 12px;
        font-weight: normal;
        text-align: left;
      }

      .rm4-person-picker {
        position: relative;
        width: inherit;

        .rm4-person-picker__input {
          width: inherit;
          height: 34px;
          font-size: 16px;
          color: #555555;
          background-color: white;
          background: white;
          padding: 0;
          border-width: 0 0 1px 0;
          border-color: #cccccc;
          -webkit-text-fill-color: #555555;
          -webkit-opacity: 1;
          opacity: 1;
          pointer-events: auto;
        }

        .rm4-person-picker__event-handler {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
      }

      .rm4-person-select {
        position: absolute;
        top: 52px;
        left: 0;
        right: 0;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);

        &:hover {
          cursor: text;
        }

        .rm4-person-select__selection {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 32px;

          .rm4-person-select__label {
            color: grey;
            font-size: 16px;
            font-weight: normal;
            margin: 0;
          }

          .rm4-person-select__calculate {
            display: flex;
            align-items: center;

            .rm4-fal {
              font-size: 22px;

              &:hover {
                cursor: pointer;
              }
            }

            span {
              width: 48px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="rm4-input-overview">
    <template v-if="!inactive">
      <div class="rm4-item" @click="$store.currentStep = $step.FIRST">
        <i class="rm4-icon rm4-fal rm4-fa-user-friends"></i>
        <span>{{ personLabel }}</span>
      </div>
      <div class="rm4-item rm4-date-picker" v-if="picker">
        <i
          class="rm4-fa rm4-fa-chevron-left rm4-left-arrow"
          @click="moveDate(true)"
          :class="{ 'rm4-disabled': disabledPrev }"
        ></i>
        <div class="rm4-date-picker-container">
          <div
            class="rm4-date-picker-mask"
            @click="showDatePicker = !showDatePicker"
          ></div>
          <i class="rm4-icon rm4-fal rm4-fa-calendar-day"></i>
          <DatePicker
            v-model="$store.date"
            value-type="date"
            prefix-class="rm4"
            :format="dateFormat"
            :lang="lang"
            :open.sync="showDatePicker"
            :editable="false"
            :clearable="false"
            :append-to-body="false"
            :disabled-date="disabledBeforeToday"
          >
          </DatePicker>
        </div>
        <i class="rm4-fa rm4-fa-chevron-right rm4-right-arrow" @click="moveDate()"></i>
      </div>
      <div
        class="rm4-item"
        @click="disabled ? '' : ($store.currentStep = $step.FIRST)"
        v-else
      >
        <i class="rm4-icon rm4-fal rm4-fa-calendar-day"></i>
        <span>{{ dateLabel }}</span>
      </div>
      <div
        class="rm4-item"
        @click="$store.currentStep = $step.THIRD"
        v-if="showTime"
      >
        <i class="rm4-icon rm4-fal rm4-fa-clock"></i>
        <span>{{ $store.time }}</span>
      </div>
    </template>
    <template v-else>
      <div class="rm4-item rm4-item--label-only">
        <i class="rm4-icon rm4-fal rm4-fa-user-friends"></i>
        <span>{{ personLabel }}</span>
      </div>
      <div class="rm4-item rm4-item--label-only">
        <i class="rm4-icon rm4-fal rm4-fa-calendar-day"></i>
        <span>{{ dateLabel }}</span>
      </div>
      <div class="rm4-item rm4-item--label-only">
        <i class="rm4-icon rm4-fal rm4-fa-clock"></i>
        <span>{{ $store.time }}</span>
      </div>
    </template>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";

export default {
  name: "InputOverview",
  props: {
    picker: {
      type: Boolean,
      default: false
    },
    showTime: {
      type: Boolean,
      default: false
    },
    inactive: {
      type: Boolean,
      default: false
    }
  },
  components: { DatePicker },
  data() {
    return {
      showDatePicker: false,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1
        }
      }
    };
  },
  methods: {
    disabledBeforeToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date < today;
    },
    moveDate(prev = false) {
      const date = new Date(this.$store.date);
      date.setDate(date.getDate() + (prev ? -1 : 1));
      this.$store.date = date;
    }
  },
  computed: {
    personLabel: function() {
      return this.$store.adults + this.$store.kids;
    },
    dateFormat() {
      return this.dateFormatted(true);
    },
    dateLabel: function() {
      return this.dateFormatted();
    },
    disabledPrev: function() {
      return this.$store.date.toDateString() === new Date().toDateString();
    }
  },
  watch: {
    "$store.date": {
      handler: function(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.showDatePicker = false;
          this.getTimes();
        }
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
.rm4-input-overview {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 22px;

  .rm4-item {
    display: flex;
    align-items: center;
    cursor: pointer;

    &.rm4-item--label-only {
      cursor: auto;
    }

    .rm4-icon {
      font-size: 22px;
      margin-right: 16px;
    }

    span {
      font-size: 14px;
    }
  }

  .rm4-date-picker {
    position: relative;

    .rm4-disabled {
      color: #a1a1a1;
      pointer-events: none;
    }

    .rm4-date-picker-container {
      position: relative;
      display: flex;
      align-items: center;

      .rm4-date-picker-mask {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 2;

        &:hover {
          cursor: pointer;
        }
      }

      .rm4-icon {
        position: absolute;
        left: 0;
        width: 22px;
        height: 22px;
        margin-right: 0;
        z-index: 1;
      }

      ::v-deep .rm4-datepicker {
        width: 100px;

        .rm4-icon-calendar {
          display: none;
        }

        .rm4-input {
          font-size: 14px;
          padding: 0 0 0 27px;
          text-align: center;
          border-width: 0;
          border-radius: 0;
          box-shadow: none;
        }
      }
    }

    .rm4-left-arrow {
      margin-right: 13px;
    }

    .rm4-right-arrow {
      margin-left: 13px;
    }
  }
}
</style>

import axios from "axios";
import "document-register-element/build/document-register-element";
import _ from "lodash";
import moment from "moment";
import Vue from "vue";
import vueCustomElement from "vue-custom-element";
import VModal from "vue-js-modal";
import App from "./App.vue";
import "./assets/sass/style.scss";
import "./assets/webfonts/fa-light-300.eot";
import "./assets/webfonts/fa-light-300.svg";
import "./assets/webfonts/fa-light-300.ttf";
import "./assets/webfonts/fa-light-300.woff";
import "./assets/webfonts/fa-light-300.woff2";

Vue.config.productionTip = false;

Vue.prototype.$step = {
  FIRST: "time-person",
  SECOND: "day-time-select",
  THIRD: "time-select",
  VIP: "vip",
  FOURTH: "customer-info",
  FIFTH: "confirm",
  LAST: "complete"
};

Vue.prototype.$frame = {
  breakfast: {
    start: moment(`${moment().format("YYYY-MM-DD")} 08:30`),
    end: moment(`${moment().format("YYYY-MM-DD")} 11:00`)
  },
  lunch: {
    start: moment(`${moment().format("YYYY-MM-DD")} 11:00`),
    end: moment(`${moment().format("YYYY-MM-DD")} 16:00`)
  },
  dinner: {
    start: moment(`${moment().format("YYYY-MM-DD")} 16:30`),
    end: moment(`${moment().format("YYYY-MM-DD")} 21:30`)
  },
  borrel: {
    start: moment(`${moment().format("YYYY-MM-DD")} 21:30`),
    end: moment(`${moment().format("YYYY-MM-DD")} 23:59`)
  }
};

Vue.prototype.$store = Vue.observable({
  production: false,
  currentStep: Vue.prototype.$step.FIRST,
  loading: false,
  isPreferred: false,
  isPreferredCustomer: false,
  isPreferredTimeslot: false,
  date: new Date(),
  time: null,
  endTime: null,
  frame: null,
  frameConfig: [],
  adults: 2,
  kids: 0,
  timeSlots: [],
  info: null,
  message: null,
  groups: null,
  error: null,
  formError: {
    email: false,
    firstName: false,
    lastName: false,
    phone: false
  },
  specialData: null,
  isVip: false,
  email: null,
  firstName: null,
  lastName: null,
  phone: null,
  birthDay: null,
  birthMonth: null,
  birthYear: null,
  birthdate: null,
  zipCode: null,
  keepInformed: false,
  completeDesc: null,
  horecaLocation: null,
  fpFp2: null,
  fpGuid: null,
  fpIframe: null,
  fpImageData: null,
  autoProceed: true,
  isOutside: false
});

switch (window.location.hostname) {
  case "development.dev-reservation-preferred.eyestone.nl":
  case "development.dev-reservation-frontend-preferred.eyestone.nl":
  case "development.beachsitedemo.eyestone.nl":
    Vue.prototype.$apiHost = `${window.location.protocol}//development.dev-reservation-api.eyestone.nl/api/`;
    Vue.prototype.$store.production = false;
    break;
  case "dev-reservation-frontend-preferred.eyestone.nl":
  case "beachsitedemo.eyestone.nl":
    Vue.prototype.$apiHost = `${window.location.protocol}//dev-reservation-api.eyestone.nl/api/`;
    Vue.prototype.$store.production = true;
    break;
  default:
    Vue.prototype.$apiHost = `${window.location.protocol}//dev-reservation-api.eyestone.nl/api/`;
    Vue.prototype.$store.production = true;
    break;
}

Vue.mixin({
  methods: {
    async setDomain(domain) {
      const url = `${Vue.prototype.$apiHost}?nocache=${new Date().getTime()}`;
      const { data } = await axios.post(url, {
        action: "domaincheck",
        domain
      });

      if (!_.get(data, "success")) {
        console.error("Unknown domain");
        return false;
      }

      Vue.prototype.$domain = domain;
      return true;
    },
    async setFpData(step, error, domain) {
      const xstatsDomain = Vue.prototype.$store.production
        ? "https://www.xstats.nl"
        : "https://dev.xstats.nl";
      Vue.prototype.$store.fpIframe = `${xstatsDomain}?v=2`;
      const date = moment(Vue.prototype.$store.date).format("DD-MM-YYYY");
      const time = _.get(Vue.prototype.$store, "time", "");
      const adults = _.get(Vue.prototype.$store, "adults", 0);
      const kids = _.get(Vue.prototype.$store, "kids", 0);
      const name =
        Vue.prototype.$store.firstName && Vue.prototype.$store.lastName
          ? `${Vue.prototype.$store.firstName} ${Vue.prototype.$store.lastName}`
          : "";
      const email = _.get(Vue.prototype.$store, "email", "");
      const phone = _.get(Vue.prototype.$store, "phone", "");

      try {
        const url = `${Vue.prototype.$apiHost}?nocache=${new Date().getTime()}`;
        const { data } = await axios.post(url, {
          action: "clientinfo"
        });
        let info =
          `&step=${step}` +
          `&error=${error}` +
          `&label=${domain}` +
          `&referer=${document.referrer}` +
          `&ip=${data.ip}` +
          `&useragent=${navigator.userAgent}` +
          `&language=${navigator.language}` +
          `&is_request=0` +
          `&adults=${adults}` +
          `&children=${kids}` +
          `&date=${date}`;

        if (time) info += `&time=${time}`;
        if (name) info += `&name=${name}`;
        if (email) info += `&email=${email}`;
        if (phone) info += `&phone=${phone}`;

        if (window.addEventListener) {
          window.addEventListener(
            "message",
            function(event) {
              if (event.origin === xstatsDomain) {
                const url = new URL(JSON.parse(event.data).fpdata);
                Vue.prototype.$store.fpFp2 = url.searchParams.get("fp2");
                Vue.prototype.$store.fpGuid = url.searchParams.get("guid");
                Vue.prototype.$store.fpImageData =
                  JSON.parse(event.data).fpdata + info;
              }
            },
            false
          );
        } else {
          window.attachEvent(
            "onmessage",
            function(event) {
              if (event.origin === xstatsDomain) {
                const url = new URL(JSON.parse(event.data).fpdata);
                Vue.prototype.$store.fpFp2 = url.searchParams.get("fp2");
                Vue.prototype.$store.fpGuid = url.searchParams.get("guid");
                Vue.prototype.$store.fpImageData =
                  JSON.parse(event.data).fpdata + info;
              }
            },
            false
          );
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getTimes(checkNextDay = false) {
      if (this.$store.isPreferred) {
        if (this.$store.email) {
          _.set(this.$store.formError, "email", !this.isValidEmail());
        }

        if (!this.isValidForms()) {
          return false;
        }
      }

      Vue.prototype.$store.loading = true;
      this.setFpData(1, 0, Vue.prototype.$domain);

      try {
        const url = `${Vue.prototype.$apiHost}?nocache=${new Date().getTime()}`;
        const payload = {
          action: "times",
          date: moment(Vue.prototype.$store.date).format("YYYY-MM-DD"),
          domain: Vue.prototype.$domain,
          persons: Vue.prototype.$store.adults + Vue.prototype.$store.kids,
          email: Vue.prototype.$store.email,
          phone: Vue.prototype.$store.phone
        };
        const { data } = await axios.post(url, payload);
        const timeSlots = _.get(data, "times", []);
        const frameConfig = _.get(data, "frames", []) || [];

        if (
          Vue.prototype.$store.frame &&
          !_.filter(
            frameConfig,
            f => f.ID == _.get(Vue.prototype.$store, "frame.ID")
          ).length
        ) {
          Vue.prototype.$store.currentStep = Vue.prototype.$step.FIRST;
        }

        Vue.prototype.$store.isPreferredCustomer = _.get(data, "pf", 0);
        Vue.prototype.$store.timeSlots = timeSlots;
        Vue.prototype.$store.frameConfig = frameConfig;
        Vue.prototype.$store.info = _.get(data, "info");
        Vue.prototype.$store.message = _.get(data, "message");
        Vue.prototype.$store.groups = !_.isEmpty(_.get(data, "groups"))
          ? _.get(data, "groups")
          : null;

        if (
          _.get(data, "offline") ||
          _.get(data, "closed") ||
          _.get(data, "error") ||
          !_.size(Vue.prototype.$store.timeSlots)
        ) {
          if (
            checkNextDay &&
            !_.get(data, "closed") &&
            !_.get(data, "offline") &&
            !_.get(data, "error") &&
            !_.size(Vue.prototype.$store.timeSlots)
          ) {
            const tomorrow = moment(Vue.prototype.$store.date)
              .add(1, "day")
              .format("YYYY-MM-DD");
            Vue.prototype.$store.date = new Date(tomorrow);
            this.getTimes();
          }

          Vue.prototype.$store.error =
            _.get(data, "description") || _.get(data, "info.text_full");
        } else {
          Vue.prototype.$store.error = null;
        }

        this.setFpData(2, 0, Vue.prototype.$domain);

        Vue.prototype.$store.loading = false;

        return true;
      } catch (error) {
        console.error(error);
        Vue.prototype.$store.loading = false;

        return false;
      }
    },
    isValidEmail() {
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      return regex.test(String(this.$store.email).toLowerCase());
    },
    isValidForms() {
      return _.values(this.$store.formError).every(error => error === false);
    },
    dateFormatted(returnFormat = false) {
      const date = moment(this.$store.date);
      const format = date.isBetween(
        moment().add(1, "day"),
        moment().add(6, "days"),
        "date",
        "[]"
      )
        ? "dddd"
        : "DD-MM-YYYY";

      if (date.isSame(moment(), "day")) {
        return returnFormat ? "[Today]" : "Today";
      }

      return returnFormat ? format : date.format(format);
    }
  }
});

Vue.use(VModal);
Vue.use(vueCustomElement);
Vue.customElement("reservation-module-v4", App);

<template>
  <div>
    <div class="rm4-card rm4-card--auto-height">
      <InputOverview :show-time="true"></InputOverview>
    </div>

    <div class="rm4-card rm4-card--p2-1">
      <div class="rm4-form">
        <div class="rm4-form__input-field">
          <div
            class="rm4-form__input-group"
            :class="{ 'rm4-form__input-group--invalid': error.firstName }"
          >
            <div class="rm4-form__input-label">First name *</div>
            <input
              class="rm4-form__input"
              type="text"
              v-model="$store.firstName"
            />
          </div>
          <div
            class="rm4-form__input-group"
            :class="{ 'rm4-form__input-group--invalid': error.lastName }"
          >
            <div class="rm4-form__input-label">Last name *</div>
            <input
              class="rm4-form__input"
              type="text"
              v-model="$store.lastName"
            />
          </div>
        </div>
        <div class="rm4-form__input-field">
          <div
            class="rm4-form__input-group"
            :class="{ 'rm4-form__input-group--invalid': error.email }"
          >
            <div class="rm4-form__input-label">Email *</div>
            <input
              class="rm4-form__input"
              type="email"
              v-model="$store.email"
            />
          </div>
        </div>
        <div class="rm4-form__input-field">
          <div class="rm4-form__input-group">
            <div class="rm4-form__input-label">Phone number *</div>
            <vue-phone-number-input
              class="rm4-form__input"
              default-country-code="NL"
              color="#000000"
              valid-color="#555555"
              error-color="#FF0000"
              :border-radius="0"
              :translations="{
                countrySelectorLabel: '',
                phoneNumberLabel: ''
              }"
              :no-example="true"
              :error="error.phone"
              v-on:update="updatePhone"
              v-model="phone"
            >
            </vue-phone-number-input>
          </div>
        </div>
        <div class="rm4-form__input-label rm4-form__input-label--centered">
          <span>Birthday</span>
          <img
            src="https://www.brandingbeach.nl/img/booking-module-assets/cake.svg"
            alt="cake"
          />
        </div>
        <div class="rm4-form__input-field">
          <div
            class="rm4-form__input-group"
            :class="{
              'rm4-form__input-group--invalid':
                error.birthDay || error.birthDate
            }"
          >
            <input
              class="rm4-form__input rm4-form__input--centered"
              placeholder="DD"
              type="tel"
              maxlength="2"
              v-model="$store.birthDay"
            />
          </div>
          <span class="rm4-form__dash">-</span>
          <div
            class="rm4-form__input-group"
            :class="{
              'rm4-form__input-group--invalid':
                error.birthMonth || error.birthDate
            }"
          >
            <div class="rm4-form__input-label"></div>
            <input
              class="rm4-form__input rm4-form__input--centered"
              placeholder="MM"
              type="tel"
              maxlength="2"
              v-model="$store.birthMonth"
            />
          </div>
          <span class="rm4-form__dash">-</span>
          <div
            class="rm4-form__input-group"
            :class="{
              'rm4-form__input-group--invalid':
                error.birthYear || error.birthDate
            }"
          >
            <div class="rm4-form__input-label"></div>
            <input
              class="rm4-form__input rm4-form__input--centered"
              placeholder="YYYY"
              type="tel"
              maxlength="4"
              v-model="$store.birthYear"
            />
          </div>
        </div>
        <div class="rm4-form__input-field">
          <div class="rm4-form__input-group">
            <div class="rm4-form__input-label">Postcode</div>
            <input
              class="rm4-form__input"
              type="text"
              pattern="[a-zA-Z0-9]+"
              maxlength="25"
              v-model="$store.zipCode"
            />
          </div>
        </div>
        <div class="rm4-form__input-field">
          <div class="rm4-form__input-group">
            <div class="rm4-form__custom-check">
              <label
                class="rm4-form__custom-check__label"
                for="keep-informed-checkbox"
              >
                <input
                  id="keep-informed-checkbox"
                  class="rm4-form__input"
                  type="checkbox"
                  v-model="$store.keepInformed"
                />
                <div class="rm4-form__input-label">Keep me informed</div>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div v-show="$store.loading" class="rm4-loader"></div>
    </div>

    <div class="rm4-actions">
      <div
        class="rm4-button rm4-button--primary"
        :class="{ 'rm4-button--disabled': $store.loading }"
        @click="validate"
      >
        Next
      </div>
      <div
        class="rm4-button"
        :class="{ 'rm4-button--disabled': $store.loading }"
        @click="$store.currentStep = $step.THIRD"
      >
        Back
      </div>
    </div>

    <modal
      name="rm4-vip-check-modal"
      :height="'auto'"
      :adaptive="true"
      :classes="'rm4-modal-content'"
    >
      <div class="rm4-modal-container">
        <div
          class="rm4-modal-close"
          @click="$modal.hide('rm4-vip-check-modal')"
        >
          <span class="rm4-modal-close-icon">&#10005;</span>
        </div>
        <div class="text">
          The selected date/time is no longer available. Please select other
          date/time.
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import VuePhoneNumberInput from "vue-phone-number-input";
import InputOverview from "./InputOverview";

export default {
  name: "CustomerInfo",
  components: {
    InputOverview,
    VuePhoneNumberInput
  },
  data: function() {
    return {
      phone: this.$store.phone,
      error: {
        email: false,
        firstName: false,
        lastName: false,
        phone: false,
        birthDay: false,
        birthMonth: false,
        birthYear: false,
        birthDate: false
      }
    };
  },
  methods: {
    updatePhone(value) {
      this.$store.phone = _.get(value, "formattedNumber");
      _.set(
        this.error,
        "phone",
        !_.get(value, "isValid") || this.$store.phone === "+31612345678"
      );
    },
    validate() {
      _.set(this.error, "email", !this.isValidEmail());
      _.set(this.error, "firstName", !this.$store.firstName);
      _.set(this.error, "lastName", !this.$store.lastName);

      if (!this.$store.phone) {
        _.set(this.error, "phone", true);
      }

      if (
        this.$store.birthDay ||
        this.$store.birthMonth ||
        this.$store.birthYear
      ) {
        let birthDay = moment(this.padded(this.$store.birthDay), "DD", true);
        let birthMonth = moment(
          this.padded(this.$store.birthMonth),
          "MM",
          true
        );
        let birthYear = moment(this.$store.birthYear, "YYYY", true);

        _.set(
          this.error,
          "birthDay",
          !this.$store.birthDay || !birthDay.isValid()
        );
        _.set(
          this.error,
          "birthMonth",
          !this.$store.birthMonth || !birthMonth.isValid()
        );
        _.set(
          this.error,
          "birthYear",
          !this.$store.birthYear || !birthYear.isValid()
        );

        if (this.isValid(["birthDay", "birthMonth", "birthYear"])) {
          birthDay = birthDay.format("DD");
          birthMonth = birthMonth.format("MM");
          birthYear = birthYear.format("YYYY");

          const date = `${birthYear}-${birthMonth}-${birthDay}`;
          const isSameOrAfter = moment(date, "YYYY-MM-DD").isSameOrAfter(
            moment()
          );

          _.set(this.error, "birthDate", isSameOrAfter);

          if (!isSameOrAfter) {
            this.$store.birthdate = date;
          }
        }
      } else {
        _.set(this.error, "birthDay", false);
        _.set(this.error, "birthMonth", false);
        _.set(this.error, "birthYear", false);
        _.set(this.error, "birthDate", false);
      }

      if (this.isValid()) {
        this.proceed();
      }
    },
    async proceed() {
      this.$store.loading = true;

      try {
        this.setFpData(3, 0, this.$domain);
        const url = `${this.$apiHost}?nocache=${new Date().getTime()}`;
        const payload = new FormData();
        payload.append("action", "validate_booking");
        payload.append(
          "datetime",
          `${moment(this.$store.date).format("YYYY-MM-DD")} ${this.$store.time}`
        );
        payload.append("name", this.name);
        payload.append("phone", this.$store.phone);
        payload.append("email", this.$store.email);
        payload.append("domain", this.$domain);
        payload.append("isLog", true);
        const response = await axios.post(url, payload);

        if (_.get(response, "data.error")) {
          this.$modal.show("rm4-vip-check-modal");
          this.$store.loading = false;
          return;
        }

        const action = "&action=check_special";
        const domain = `&domain=${this.$domain}`;
        const pax = `&totalpax=${this.$store.adults + this.$store.kids}`;
        const date = `&date=${moment(this.$store.date).format("YYYY-MM-DD")}`;
        const time = `&time=${this.$store.time}`;
        const { data } = await axios.get(
          `${url}${action}${domain}${pax}${date}${time}`
        );

        if (this.$store.isVip && !_.get(data, "specialmaxpax")) {
          this.$modal.show("rm4-vip-check-modal");
          this.$store.loading = false;
          return;
        }

        this.$store.currentStep = this.$step.FIFTH;
      } catch (error) {
        console.error(error);
      }

      this.$store.loading = false;
    },
    padded(value, length = 2) {
      return value ? String(value).padStart(length, "0") : value;
    },
    isValid(keys) {
      const errors = keys ? _.pick(this.error, keys) : this.error;

      return _.values(errors).every(e => e === false);
    }
  },
  computed: {
    name: function() {
      return `${this.$store.firstName} ${this.$store.lastName}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.rm4-form__input-label {
  width: inherit;
  color: grey;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 5px;

  &.rm4-form__input-label--centered {
    display: flex;
    align-items: center;
  }

  img {
    width: 16px;
    height: 16px;
    margin-left: 5px;
    align-self: flex-start;
  }
}

.rm4-form {
  padding: 16px 8px 0;

  .rm4-form__input-field {
    display: flex;
    margin: 0 -8px 16px;
    align-items: flex-end;

    &:last-child {
      margin-bottom: 0;
    }

    .rm4-form__input-group {
      width: 100%;
      margin: 0 8px 16px;

      &.rm4-form__input-group--invalid {
        & * {
          color: red !important;
          border-color: red !important;
        }
      }

      .rm4-form__input {
        width: inherit;
        height: 34px;
        font-size: 16px;
        color: #555;
        border-width: 0 0 1px 0;
        border-color: #ccc;
        border-radius: 0;

        &.rm4-form__input--centered {
          text-align: center;
          font-size: 14px;
        }
      }

      .rm4-form__custom-check {
        display: flex;
        align-items: center;

        .rm4-form__custom-check__label {
          display: flex;

          .rm4-form__input {
            height: 14px;
            margin: 0 8px 0 0;
          }
        }
      }
    }

    .rm4-form__dash {
      align-self: center;
    }
  }
}
</style>

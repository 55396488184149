<template>
  <div>
    <div class="rm4-card rm4-card--auto-height">
      <InputOverview :show-time="true"></InputOverview>
    </div>

    <div class="rm4-card rm4-card--p2-1">
      <div class="rm4-card__sub-label" v-html="$store.specialData.only"></div>
    </div>

    <div class="rm4-actions">
      <div class="rm4-button rm4-button--primary" @click="nextStep">
        Yes, please!
      </div>
      <div
        class="rm4-button rm4-button--secondary"
        @click="$store.currentStep = $step.THIRD"
      >
        No thank you
      </div>
      <div class="rm4-button" @click="$store.currentStep = $step.THIRD">
        Back
      </div>
    </div>
  </div>
</template>

<script>
import InputOverview from "./InputOverview";

export default {
  name: "VipConfirm",
  components: { InputOverview },
  methods: {
    nextStep() {
      this.$store.isVip = true;
      this.$store.currentStep = this.$step.FOURTH;
    }
  }
};
</script>

<style lang="scss" scoped></style>
